


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-weight: normal;
  font-family: "Maven Pro", sans-serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 130%;
  scroll-behavior: smooth;
}

:root{
  --darkThemeBgColor: #0f1013;
  --darkThemeTextColor: #efefef;
}

.body{
  --darkThemeBgColor: #0f1013;
  --darkThemeTextColor: #efefef;
}

.darkMode{
  --darkThemeBgColor: #efefef;
  --darkThemeTextColor: #0f1013;
}

.body{ background-color: var(--darkThemeBgColor); }
h1{ margin: .67em 0; }
h3{ font-size: 34px; margin-top: 20px; margin-bottom: 10px; font-weight: 500; line-height: 130%; }

.navbar{ 
  justify-content: space-between; 
  align-items: center;
  margin-left: auto; 
  margin-right: auto; 
  padding-left: 24px; 
  padding-right: 24px;
  display: flex;
  height: 90px;
  position: sticky;
  top: 0;
  z-index: 21;
  background: #0f1013;
  width: 100%;
}

.leftNavbar{ height: 23px; }
.leftNavbar p, .leftNavbar span{ color: white; font-size: 26px; font-weight: bold; }
.leftNavbar span{ color: #126fff; }
.rightNavbar{ background-color: #212225; border-radius: 60px; justify-content: flex-end; align-items: center; padding: 6px; display: flex; height: 42px; }
.navMenu{ justify-content: flex-end; align-items: center; display: flex; float: right; position: relative; }
.navMenu .nameContainer{ text-align: left; z-index: 900; margin-left: auto; margin-right: auto; display: inline-block; position: relative; }
.navMenu .nameContainer .toogle{ grid-column-gap: 6px; justify-content: center; align-items: center; padding: 8px 14px 8px 16px; display: flex; color: #efefef; font-weight: 500; font-size: 16px; }
.rightNavbarSelect{
  grid-column-gap: 6px;
  justify-content: center;
  align-items: center;
  padding: 4px 14px 4px 16px;
  display: flex;
  background-color: var(--darkThemeTextColor);
  color: var(--darkThemeBgColor);
  font-weight: 500;
  font-size: 16px;
  border-radius: 12px;
  max-height: 36px;
  min-height: 36px;
  border: none;
  outline: none;
}

.layout{ max-width: 1700px; margin-left: auto; margin-right: auto; padding-left: 24px; padding-right: 24px; display: block; }
/* .layoutContainer{ color: #efefef; background-image: linear-gradient(90deg, rgba(33, 35, 49, .8), rgba(33, 35, 49, .3) 54%, rgba(33, 35, 49, 0)), url('./Images/image1.webp'); background-position: 0 0, 50%; background-repeat: repeat, no-repeat; background-size: auto, cover; border-radius: 32px; flex-direction: column; justify-content: flex-end; align-items: flex-start; padding: 210px 120px 120px 70px; display: flex; position: relative; } */
.name{ color: #efefef; }

/* .layoutContainer .hero{
  grid-row-gap: 40px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
} */

/* .layoutContainer h1{
  font-size: 100px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -4px;
} */

/* .layoutContainer p{
  max-width: 490px;
} */

/* .btnContainer{
  margin-top: 16px;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.btnContainer button{
  height: 48px;
  padding-right: 4px;
  grid-column-gap: 40px;
  background-color: #126fff;
  color: #efefef;
  border-radius: 80px;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  text-decoration: none;
  display: flex;
  max-width: 100%;
} */

.circle{
  background-color: #efefef;
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.circle img{
  width: 24px;
  height: 24px;
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
}

.section2MainContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.section2Description{
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 752px;
  margin-left: auto;
  margin-right: 80px;
  display: flex;
}

.section2DescriptionHeading p, .section2DescriptionHeading span{
  font-size: 32px;
  font-weight: 500;
  line-height: 125%;
  color: var(--darkThemeTextColor);
}

.section2DescriptionHeading span{
  color: #126fff;
}

.exapndables{
  grid-row-gap: 0px;
  flex-direction: column;
  display: flex;
  width: 100%;
}

.expandableHeading{
  border-top: 1px solid;
  cursor: pointer;
  padding: 40px 0;
  height: 122px;
  overflow: hidden;
  border-color: var(--darkThemeTextColor);
}

.expandableHeading.active{
  height: auto;
}

.expandableHeadingContainer{
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.expandableHeadingContainer p{
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 24px;
  font-size: 32px;
  line-height: 120%;
  color: var(--darkThemeTextColor);
}

.plusExpandIcon{
  flex: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  position: relative;
}

.plusExpandIcon .line1, .plusExpandIcon .line2{
  background: var(--darkThemeTextColor);
  border-radius: 1px;
  width: 18px;
  height: 2px;
}

.plusExpandIcon .line2{
  transform: rotate(90deg);
  position: absolute;
  transition: all 0.6s;
}

.expandableHeading.active .plusExpandIcon .line2{
  transform: rotate(0deg);
}

.section2expandableHeadingContainer{
  transform-origin: 50% 0;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section2expandableHeadingContainer p{
  transform-origin: 50% 0;
  font-size: 20px;
  margin-bottom: 0;
  padding-top: 40px;
  padding-bottom: 12px;
  color: var(--darkThemeTextColor);
}

.section2expandableHeadingContainer p span{
  color: #126fff;
}

.section3Layout{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.section3Layout .section3line{
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #efefef;
}

.section3Logo{
  border-top: 1px solid rgba(239, 239, 239, .3);
  border-bottom: 1px solid rgba(239, 239, 239, .3);
  padding-top: 40px;
  padding-bottom: 40px;
}

.section3LogoContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
}

.logoWide{
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.section4{
  padding: 120px 0;
}

.section4BlockContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.section4Block1{
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 64px;
}

.section4Block1Heading{
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tag{
  border-right: 6px solid #126fff;
  border-left: 6px solid #126fff;
  background-color: #212225;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.tag div{
  font-size: 16px;
  font-weight: 500;
  color: #efefef;
}

.section4Block1Heading h2, .section4Block1Heading span{
  font-weight: 500;
  line-height: 120%;
  font-size: 48px;
  color: var(--darkThemeTextColor);
}

.section4Block1Heading span{
  color: #126fff;
}

.section4Block1Title{
  font-size: 22px;
  color: var(--darkThemeTextColor);
  line-height: 130%;
}

.section4Block2{
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.section4Block2Container{
  grid-area: span 1 / span 1 / span 1 / span 1;
  grid-column-gap: 100px;
  grid-row-gap: 100px;
  background-color: #18191c;
  border-radius: 16px;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
  display: flex;
}

.section4Block2Container img{
  width: 144px;
  height: 144px;
  object-fit: fill;
}

.section4Block2ContainerDescription{
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section4Block2ContainerDescription h3{
  margin: 0;
  color: #efefef;
}

.subTitle{
  font-size: 26px;
  font-weight: 500;
  line-height: 130%;
  color: #efefef;
}

.section4Block3{
  margin-top: 64px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section4Block3 button{
  border: 1px solid #126fff;
  color: var(--darkThemeTextColor);
  background-color: transparent;
  grid-column-gap: 40px;
  border-radius: 80px;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding-left: 24px;
  padding-right: 6px;
  text-decoration: none;
  display: flex;
}

.videoSection{
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 600px;
  max-height: 980px;
  padding-top: 240px;
  padding-bottom: 200px;
  display: flex;
  position: relative;
}

.videoSection video{
  object-fit: cover;
  z-index: 0;
  background-position: 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  opacity: 0.8;
}

.section5Layout{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  display: block;
}

.videoContent{
  z-index: 20;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
  opacity: 1;
}

.videoContent div{
  z-index: 10;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.videoContent div h1{
  font-size: 100px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 120%;
  color: #efefef;
}

.videoBtn{
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  background-color: #126fff;
  color: #efefef;
  border-radius: 84px;
  justify-content: flex-start;
  align-items: center;
  padding: 18px 40px 18px 24px;
  text-decoration: none;
  display: flex;
  border: none;
}

.section6Slider{
  padding: 120px 0;
}

.section6MainContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  display: block;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: var(--darkThemeBgColor);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.section6SliderImg{
  height: 28px;
  object-fit: contain;
  margin-bottom: 48px;
}

.sliderHeading, .sliderHeading span{
  text-align: center;
  letter-spacing: .1px;
  font-size: 40px;
  font-weight: 500;
  line-height: 130%;
  margin-top: 20px;
  margin-bottom: 10px;
  max-width: 870px;
  color: var(--darkThemeTextColor);
}

.sliderHeading span{
  color: #126fff;
}

.swiper-button-next, .swiper-button-prev{
  width: 64px;
  height: 64px;
  background: #126fff;
  border-radius: 50%;
  color: white;
}

.swiper-button-next::after{
  font-size: 24px;
}

.swiper-button-prev::after{
  font-size: 24px;
}

.section7Container{
  padding: 24px 0 120px 0;
}

.section7BlockContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.section7Cards{
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.section7singleCard{
  grid-column-gap: 64px;
  grid-row-gap: 64px;
  background-color: #18191c;
  color: #efefef;
  border-radius: 16px;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  text-decoration: none;
  display: flex;
}

.section7Title{
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section7readMore{
  color: #126fff;
}

.section8Stack{
  background-color: #126fff;
  color: #efefef;
  padding: 120px 0;
}

.section8BlockContainer{
  width: 100%;
  max-width: 1122px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  display: block;
}

.section8BlockContainerHeading{
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section8Heading{
  border-right-color:#efefef;
  border-left-color:#efefef;
  background-color: #2c7fff;
  border-right: 6px solid ;
  border-left: 6px solid ;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.techStack{
  font-size: 48px;
  font-weight: 500;
  line-height: 120%;
}

.section8CircleContainer{
  justify-content: flex-start;
  align-items: center;
  margin: 64px auto;
  display: flex;
  overflow: hidden;
  width: 80%;
}

.section8MiddleCircleContainer{
  gap: 2vw;
  flex-direction: row;
  flex: none;
  align-items: center;
  display: flex;
  width: 200vw;
  animation-duration: 10s;
  animation-name: slidein;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.section8InnerCircleContainer{
  gap: 2vw;
  display: flex;
  flex: none;
  justify-content: flex-start;
  align-items: center;
}

@keyframes slidein {
  from{
      transform: translateX(2%);
  }

  to{
      transform: translateX(-28%);
  }
}

.section8Circle{
  background-color: #fffeff;
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 11vw;
  height: 11vw;
  max-height: 350px;
  display: flex;
}

.section8Circle img{
  height: 51%;
}

.section8LowerContainer{
  width: 100%;
  max-width: 1122px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
}

.section8WrapText{
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 720px;
  display: flex;
}

.section8Title{
  font-size: 26px;
  font-weight: 500;
  line-height: 130%;
}

.section8btnContainer{
  border-radius: 80px;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding-left: 24px;
  padding-right: 6px;
  text-decoration: none;
  display: flex;
  grid-column-gap: 40px;
  background-color: #efefef;
  color: #126fff;
  border: none;
}

.section8btnContainerCircle{
  background-color: #126fff;
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  display: flex;
  overflow: hidden;
}

.section9GallerySection{
  padding: 120px 0;
}

.section9Container{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: 0 auto;
  display: block;
}

.gridGalleryHalf{
  grid-column-gap: 112px;
  grid-row-gap: 112px;
  perspective: 2000px;
  grid-template-rows: auto;
  grid-template-columns: 1.3fr .7fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.gridGalleryHalf img{
  border-radius: 16px;
  max-width: 100%;
  display: inline-block;
}

.gridGalleryHalf .img1{
  /* transform: rotateY(39deg); */
  z-index: 1;
  position: relative;
  /* left: 13%; */
  animation-duration: 10s;
  animation-name: gallery1;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

.gridGalleryHalf .img2{
  z-index: 2;
  position: relative;
  top: 20%;
  animation-duration: 20s;
  animation-name: gallery2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes gallery1 {
  0%{
      left: 0%;
      transform: rotateY(0deg);
  }
  
  50%{
      left: 13%;
      transform: rotateY(39deg);
  }

  100%{
      left: 0%;
      transform: rotateY(0deg);
  }
}

@keyframes gallery2 {
  0%{
      right: 0%;
      transform: rotateY(0deg);
  }
  
  50%{
      right: 44%;
      transform: rotateY(-48deg);
  }

  100%{
      right: 0%;
      transform: rotateY(0deg);
  }
}

.section10container{
  padding: 120px 0;
}

.section10MainContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  display: block;
}

.section10WrapContainer{
  z-index: 10;
  grid-column-gap: 52px;
  grid-row-gap: 52px;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1060px;
  margin-left: auto;
  display: flex;
  position: relative;
}

.section10WrapContainer img{
  vertical-align: middle;
  max-width: 100%;
  display: inline-block;
  height: 56px;
}

.section10Text{
  font-size: 74px;
  font-weight: 300;
  line-height: 120%;
  color: var(--darkThemeTextColor);
}

.section11Container{
  padding: 120px 0;
  background-color: #126fff;
  color: #efefef;
  display: block;
  position: relative;
}

.section11blockContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  display: block;
}

.section11blockHeading{
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
  margin-bottom: 100px;
}

.section11CenterTagHeading{
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.section11accent{
  background-color: #2c7fff;
  border-right: 6px solid #efefef;
  border-left: 6px solid #efefef;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.section11Tabs{
  z-index: 10;
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
  position: relative;
}

.section11Tab1{
  grid-row-gap: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  display: flex;
}

.section11TabHeading{
  color: rgba(252, 252, 252, .5);
  text-transform: uppercase;
  background-color: rgba(221, 221, 221, 0);
  padding: 8px 0;
  font-size: 64px;
  font-weight: 500;
  line-height: 110%;
  cursor: pointer;
}

.section11TabHeading.active{
  color: #efefef;
}

.section11TabContent{
  flex: 1;
  max-width: 520px;
  margin-left: auto;
  padding-top: 8px;
  display: block;
  position: relative;
  overflow: hidden;
}

.section11TabDescription1{
  grid-row-gap: 40px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.section11TabDescription1 img{
  border-radius: 16px;
  width: 100%;
}

.hidden{
  display: none;
}

.section11ContainerBgImage{
  z-index: 1;
  position: absolute;
  top: auto;
  bottom: 0%;
  left: auto;
  right: 0%;
}

.section12Container{
  padding: 120px 0 64px 0;
}

.section12MainContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  display: block;
}

.section12UpperHeading{
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
  margin-bottom: 80px;
}

.section12Tag{
  border-right: 6px solid #126fff;
  border-left: 6px solid #126fff;
  background-color: #212225;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  color: #efefef;
}

.section12UpperHeading h2, .section12UpperHeading span{
  font-size: 48px;
  font-weight: 500;
  line-height: 120%;
  margin: 0;
  color: var(--darkThemeTextColor);
}

.section12UpperHeading span{
  color: #126fff;
}

.section12LowerContainer{
  grid-column-gap: 80px;
  grid-row-gap: 80px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 320px;
  grid-auto-columns: 1fr;
  display: grid;
}

.section12LowerLeftContainer{
  grid-area: span 1 / span 1 / span 1 / span 1;
  max-width: 650px;
}

.lowerleftParagraph{
  font-size: 22px;
  line-height: 130%;
  color: var(--darkThemeTextColor);
}

.section12LowerRightContainer{
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
  max-width: 230px;
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section12numberWrap{
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  text-align: left;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section12numberText{
  color: #126fff;
  font-size: 80px;
  font-weight: 700;
  line-height: 110%;
}

.section12numberHeading{
  font-weight: 500;
  color: var(--darkThemeTextColor);
}

.section12divider{
  background-color: #59595b;
  width: 100%;
  height: 1px;
}

.section13{
  padding: 120px 0;
  display: block;
}

.section13Container{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
  display: block;
}

.section13gridContainer{
  grid-column-gap: 64px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
  grid-row-gap: 16px;
}

.section13gridContainer .section13gridContainerImage{
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
  max-width: 590px;
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: stretch;
  justify-self: stretch;
}

.section13rightContainer{
  grid-area: span 1 / span 1 / span 1 / span 1;
  align-self: center;
  justify-self: center;
  max-width: 606px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 52px;
  padding-bottom: 52px;
  width: 100%;
}

.section13rightInnerContainer{
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  background-color: #18191c;
  border-radius: 12px;
  flex-direction: column;
  align-items: flex-start;
  padding: 64px 56px;
  display: flex;
  color: #efefef;
}

.section13rightInnerContainer img{
  height: 28px;
}

.section13TopContact{
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section13Heading{
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  line-height: 120%;
  font-size: 38px;
  color: #efefef;
}

.section13divider{
  background-color: #59595b;
  width: 100%;
  height: 1px;
}

.section13bottomContact{
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section13bottomIconContainers{
  grid-column-gap: 12px;
  color: #efefef;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.footer{
  background-color: #18191c;
  color: #efefef;
  padding-top: 120px;
  padding-bottom: 64px;
}

.footerMainContainer{
  width: 100%;
  max-width: 1440px;
  padding-left: 24px;
  padding-right: 24px;
  margin: auto;
}

.section14UpperFooter{
  justify-content: space-between;
  align-items: flex-start;
  display: flex;
}

.section13UpperLeftFooter{
  grid-row-gap: 40px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section13LogoimgContainer{
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 30px;
  display: flex;
  max-width: 100%;
}

.section13FooterContactDetails{
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.footerSingleContactList{
  grid-column-gap: 8px;
  color: #efefef;
  justify-content: flex-start;
  align-items: center;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  max-width: 100%;
}

.footerSingleContactList img{
  width: 24px;
  height: 24px;
}

.section13UpperRightFooter{
  grid-column-gap: 32px;
  flex: 1;
  justify-content: space-evenly;
  align-items: flex-start;
  max-width: 760px;
  display: flex;
  grid-row-gap: 16px;
  gap: 20px;
}

.section13columnFooterLink{
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section13heading{
  color: #efefef;
  font-size: 16px;
  font-weight: 700;
}

.section13FooterLinks{
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.section13FooterLinks div{
  color: #efefef;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}

.section13FooterDivider{
  background-color: #59595b;
  width: 100%;
  height: 1px;
  margin-top: 80px;
  margin-bottom: 64px;
}

.section15Image1{
  clip-path: polygon(100% 0, 100% 71%, 71% 100%, 20% 25%, 69% 0);
  position: absolute;
  right: -106px;
  top: -93px;
  display: block;
  border-radius: 0 32px 0 0;
  scale: 80%;
  z-index: -1;
}

.section15Image2{
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  position: absolute;
  right: -70px;
  bottom: -165px;
  display: block;
  scale: 0.6;
  z-index: -1;
}

.section15BlurContainer{
  width: 600px;
  height: 600px;
  background: #126fff;
  border-radius: 50%;
  filter: blur(223px);
  position: absolute;
  top: 20%;
  left: 7%;
  z-index: -2;
}

.section15heading1{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.section15heading1 h1{
  color: var(--darkThemeTextColor);
  font-size: 100px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: -4px;
}

.section15heading1 p, .section15heading1 span{
  font-size: 32px;
  font-weight: 500;
  line-height: 125%;
  color: var(--darkThemeTextColor);
  margin-top: 14px;
  text-align: left;
}

.section15heading1 span{
  color: #126fff;
}

.section15SwiperContainer{
  height: 890px; 
  width: 100%; 
  padding: 209px 120px 120px 70px; 
  border-radius: 32px; 
  overflow: hidden;
}


.checkbox-wrapper-64 input[type="checkbox"] {
  visibility: hidden;
  display: none;
}

.checkbox-wrapper-64 *,
.checkbox-wrapper-64 ::after,
.checkbox-wrapper-64 ::before {
  box-sizing: border-box;
}

/* The switch - the box around the slider */
.checkbox-wrapper-64 .switch {
  font-size: 17px;
  display: inline-block;
  width: 3.5em;
  height: 2em;
  position: fixed;
  z-index: 21;
  bottom: 10px;
  right: 30px;
}

/* Hide default HTML checkbox */
.checkbox-wrapper-64 .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.checkbox-wrapper-64 .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  border: 1px solid #adb5bd;
  transition: .4s;
  border-radius: 30px;
}

.checkbox-wrapper-64 .slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  border-radius: 20px;
  left: 2px;
  bottom: 4px;
  background-color: #adb5bd;
  transition: .4s;
}

.checkbox-wrapper-64 input:checked + .slider {
  background-color: #126fff;
  border: 1px solid #126fff;
}

.checkbox-wrapper-64 input:focus + .slider {
  box-shadow: 0 0 1px #126fff;
}

.checkbox-wrapper-64 input:checked + .slider:before {
  transform: translateX(1.4em);
  background-color: #fff;
}

@media screen and (width < 1383px) {
  .section15Image1 { scale: 65%; right: -170px; top: -160px; }
  .section15Image2 { right: -200px; }
}

@media screen and (width < 1229px) {
  .section15SwiperContainer { padding-left: 30px; }
  .gridGalleryHalf { display: none; }
}

@media screen and (width < 1180px) {
  .section4Block2, .section7Cards{ display: flex; flex-direction: column; }
  .section4Block2Container { align-items: center; }
}

@media screen and (width < 1167px) {
  .section15heading1 h1 { font-size: 60px; }
  .section15SwiperContainer { height: 509px; padding-top: 109px; }
  .section15Image1 { scale: 40%; right: -325px; top: -285px; }
  .section15Image2 { right: -335px; scale: 40%; bottom: -250px; }
  .swiper-button-next, .swiper-button-prev { display: none; }
}

@media screen and (width < 1108px) {
  .section13gridContainer { display: flex; align-items: center; flex-direction: column; }
  .section13gridContainer .section13gridContainerImage { margin: auto; }
}

@media screen and (width < 1013px) {
  .section8CircleContainer { width: 95%; }
}

@media screen and (width < 888px) {
  .section11Tabs { flex-direction: column; align-items: center; }
  .section11Tab1 { margin-right: unset; display: flex; justify-content: space-between; flex-direction: row; width: 100%; padding-bottom: 50px; }
  .section11TabContent { margin-left: unset; }
  .section3Logo { display: none; }
}

@media screen and (width < 966px) {
  .section15heading1 { width: 55%; }
  .section15heading1 h1 { font-size: 40px; letter-spacing: 0px; }
}

@media screen and (width < 856px) {
  .section12LowerContainer { grid-column-gap: 30px; display: flex; flex-direction: column; }
  .section12LowerLeftContainer { max-width: unset; }
  .section12LowerRightContainer { max-width: unset; flex-direction: row; }
  .section12divider { display: none; }
  .section12numberWrap { width: 48%; }
  .section12numberText { width: 100%; text-align: center; font-size: 60px; }
  .section12numberHeading { width: 100%; text-align: center; }
}

@media screen and (width < 797px) {
  .section11Tab1 { justify-content: space-evenly; }
  .section11TabHeading { font-size: 40px; }
}

@media screen and (width < 750px) {
  .section15Image2 { display: none; }
  .section15SwiperContainer { padding-top: 220px; }
  .section15heading1 { width: 90%; }
  .section15Image1 { clip-path: polygon(100% 0, 100% 100%, 100% 100%, 20% 25%, 69% 0); }
  .rightNavbar { display: none; }
  .section13UpperRightFooter { display: none; }
  .videoContent div h1 { font-size: 50px; }
}

@media screen and (width < 650px) {
  .section12numberText { font-size: 30px; }
  .section12numberWrap { grid-row-gap: 20px; }
  .section12numberHeading { font-size: 20px; }
  .section10Text { font-size: 50px; }
  .section2Description { margin-right: 20px; }
  .sliderHeading, .sliderHeading span { font-size: 34px; margin: 20px auto; width: 90%; }
}

@media screen and (width < 550px) {
  .section13rightInnerContainer { padding: 38px 30px; }
  .section11TabHeading { font-size: 25px; }
  .section11Tab1 { justify-content: space-between }
  .videoContent div h1 { font-size: 40px; }
  .sliderHeading, .sliderHeading span { font-size: 25px; }
  .expandableHeadingContainer p { font-size: 25px; }
  .section2DescriptionHeading p, .section2DescriptionHeading span{ font-size: 25px; }
  .section15SwiperContainer { height: 600px; }
  .section15SwiperContainer { padding-top: 330px; }
  .section15heading1 { width: 150%; }
  .section15heading1 h1 { font-size: 35px; }
  .leftNavbar p, .leftNavbar span { font-size: 24px; }
}